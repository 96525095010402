<template>
  <div id="section2">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container class="pt-5">
          <b-row>
            <b-col md="6" sm="12">
              <h3>
                <strong>{{$t("payTitle1")}}</strong>
              </h3>
              <p v-for="(item, index) in payText" :key="index">
                <span v-for="(item1, index1) in item.text" :key="index1">
                  <strong v-if="item1.txtS">
                    {{item1.txt}}
                  </strong>
                  <span v-else>
                    {{item1.txt}}
                  </span>
                </span>
              </p>
              <br>
              <h3>
                <strong>{{$t("payTitle2")}}</strong>
              </h3>
              <div>
                <b-img :src="imgTitle2"/>
              </div>
            </b-col>
            <b-col md="6" sm="12">
              <h3>
                <strong>{{$t("payTitle3")}}</strong>
              </h3>
              <b-form>
              <b-row>
                <b-col cols="12" class="pb-2">
                  <b-form-group :label="payClienteT">
                    <b-form-radio-group
                      v-model="form.cliente"
                      :options="payCliente"
                      name="cliente"
                      stacked
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-group :label="payNegocioT">
                    <b-form-radio-group
                      v-model="form.negocio"
                      :options="payNegocio"
                      name="negocio"
                      stacked
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-input id="id-negcio" 
                      v-model="form.negocio" disabled/>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-input id="id-email" 
                      v-model="form.email" required
                      :placeholder="$t('payEmail')"/>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-input id="id-nombre" 
                      v-model="form.nombre" required
                      :placeholder="$t('payNombre')"/>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-input id="id-apellido" 
                      v-model="form.apellido" required
                      :placeholder="$t('payApellido')"/>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-input id="id-empresa" 
                      v-model="form.empresa" required
                      :placeholder="$t('payEmpresa')"/>
                </b-col>
                <b-col cols="12" class="pb-2">
                  <b-form-input id="id-pais" 
                      v-model="form.pais" required
                      :placeholder="$t('payPais')"/>
                </b-col>
                <b-col sm="12" class="pb-2">
                  <br>
                  <b-form-checkbox id="id-acept" 
                      v-model="form.acept" 
                      >
                      {{$t("payAcept")}}
                  </b-form-checkbox>
                </b-col>
                <b-col sm="12" class="pb-2 ">
                  <br>
                  <b-button @click="enviarFormulario()" class="buttonAzul">
                    {{$t("payBtn")}}
                  </b-button>
                </b-col>
              </b-row>
              <br />
            </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "es":{
    "payTitle1": "EL FUTURO DE LA ACEPTACIÓN DE PAGOS 2019."
    , "payText": [
      {
        "text": [
          {
            "txtS": false
            , "txt": "Descubre las "
          },
          {
            "txtS": true
            , "txt": "tendencias en aceptación de medios de pago en los comercios, "
          },
          {
            "txtS": true
            , "txt": "y sácale partido a tu negocio. Cuanto más sepas sobre cómo los hábitos de los consumidores están impactando en la forma de comprar y contratar nuevos servicios, más preparado estarás para hacer crecer tu negocio."
          }
        ]
      },
      {
        "text": [
          {
            "txtS": false
            , "txt": "La integración del mundo digital con el mundo físico ha propiciado una explosión de métodos de pago alternativos que facilitan las transacciones instantáneas y a nivel global. Los pagos sin contacto y los pagos móviles vía app se popularizan a un ritmo tan vertiginoso que son muchos los que empiezan a hablar de una posible y futura sociedad sin dinero en efectivo."
          }
        ]
      },
      {
        "text": [
          {
            "txtS": false
            , "txt": "La realización de este estudio está basado en "
          },
          {
            "txtS": true
            , "txt": "entrevistas a 500 empresas y 500 consumidores, "
          },
          {
            "txtS": false
            , "txt": "así como con la colaboración de destacados actores de la industria de medios de pago."
          }
        ]
      }
    ]
    , "payTitle2": "HAN COLABORADO EN ESTE ESTUDIO:"
    , "payTitle3": "DESCARGA EL INFORME: EL FUTURO DE LA ACEPTACIÓN DE PAGOS 2019."
    , "payNombre": "Nombres"
    , "payApellido": "Apellidos"
    , "payEmpresa": "Empresa"
    , "payEmail": "E-mail"
    , "payPais": "Pais"    
    , "payClienteT": "¿Eres cliente de UniversalPay? *"
    , "payCliente":[
      "Si"
      ,"No"
      ]
    , "payNegocioT": "¿Qué describe mejor tu negocio? *"
    , "payNegocio": [
      "Vendo online"
      ,"Vendo en establecimiento"
      ,"Vendo online y con establecimiento"
      ,"Otro"
    ]            
    , "payAcept": " Accedo a recibir comunicaciones de productos de UniversalPay y servicios relacionados que puedan ser de mi interés (podrás darte de baja en cualquier momento)."
    , "payBtn": "Enviar"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/pay/logo_pay_color.png"),
      imgTitle2: require("@/assets/images/pay/logos_landing-1.png"),
      form: {
        nombre: ""
        , apellido: ""
        , empresa: "" 
        , email: ""
        , pais: ""
        , negocio: ""
        , cliente: null
        , acept: null
      }
      
    };
  },
  methods: {
    enviarFormulario(){
      console.log(this.form);
      let variant = 'default';
      this.$bvToast.toast('Enviado formulario ... ', {
          title: this.$t('message.pay.page4'),
          variant: variant,
          autoHideDelay: 3000,
          solid: true
        });
      this.form = {
        nombre: ""
        , apellido: ""
        , empresa: "" 
        , email: ""
        , pais: ""
        , negocio: ""
        , cliente: null
        , acept: null
      }  
    }
  },
  computed: {
    payText() {
      return this.$t("payText");
    },
    payClienteT() {
      return this.$t("payClienteT");
    },
    payCliente() {
      return this.$t("payCliente");
    },
    payNegocio() {
      return this.$t("payNegocio");
    },
    payNegocioT() {
      return this.$t("payNegocioT");
    }
  }
};
</script>
